import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "wrap"
};
const _hoisted_2 = {
  class: "left"
};
const _hoisted_3 = {
  class: "dialog-footer"
};
import { reactive, ref, computed } from 'vue';
import { AllDict } from '@/utils/common';
import { sendSfOrder } from '@/api/order';
import { ElMessage } from 'element-plus';
import dayjs from 'dayjs';
const formLabelWidth = '100px';
export default {
  __name: 'modal-sf-order',
  emits: ['refresh'],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const dialogVisible = ref(false);
    const emit = __emit;
    const formRef = ref();
    const loading = ref(false);

    const generateFrom = () => {
      return {
        orderId: '',
        trade_uuid: '',
        name: '',
        sourceArea: 'CHN',
        expressTypeId: '1',
        sendStartTm: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        // 收件人信息
        acceptInfo: {
          address: '',
          contact: '',
          tel: ''
        },
        // 寄件人信息
        sendInfo: {
          address: '河南省郑州市中原区华强广场云翎中心3812室',
          contact: '史先生',
          tel: '18645959323'
        }
      };
    };

    const formModel = reactive(generateFrom());
    const rules = reactive({
      name: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }],
      trade_uuid: [{
        required: true,
        message: '请输入',
        trigger: 'change'
      }],
      sendStartTm: [{
        required: true,
        message: '请选择',
        trigger: 'change'
      }],
      'acceptInfo.contact': [{
        required: true,
        message: '请输入',
        trigger: 'change'
      }],
      'acceptInfo.address': [{
        required: true,
        message: '请输入',
        trigger: 'change'
      }],
      'acceptInfo.tel': [{
        required: true,
        message: '请输入',
        trigger: 'change'
      }],
      'sendInfo.contact': [{
        required: true,
        message: '请输入',
        trigger: 'change'
      }],
      'sendInfo.address': [{
        required: true,
        message: '请输入',
        trigger: 'change'
      }],
      'sendInfo.tel': [{
        required: true,
        message: '请输入',
        trigger: 'change'
      }]
    });

    const showModal = data => {
      Object.assign(formModel, generateFrom());
      Object.assign(formModel, {
        expressState: data.expressState,
        orderId: data.orderId,
        name: data.subject,
        trade_uuid: data.trade_uuid,
        acceptInfo: {
          address: data.address,
          contact: data.recipient,
          tel: data.phone
        }
      });
      dialogVisible.value = true;
    };

    const hideModal = () => {
      dialogVisible.value = false;
    };

    const title = computed(() => {
      return `订单ID: ${formModel.orderId}`;
    });

    const sendSfOrderFn = async () => {
      try {
        const sendParams = JSON.parse(JSON.stringify(formModel));
        Object.assign(sendParams, {
          contactInfoList: [formModel.sendInfo, formModel.acceptInfo]
        });
        const resData = await sendSfOrder(sendParams);

        if (resData && resData.status === 200) {
          ElMessage({
            message: resData.data || '发货成功！',
            type: 'success'
          });
          emit('refresh');
          hideModal();
        }

        loading.value = false;
      } catch (err) {
        console.log(err);
        loading.value = false;
      }
    };

    const submitForm = async formEl => {
      loading.value = true;
      await formRef.value.validate((valid, fields) => {
        if (valid) {
          console.log('submit!');
          sendSfOrderFn();
        } else {
          console.log('error submit!', fields);
        }
      });
    };

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_card = _resolveComponent("el-card");

      const _component_el_tab_pane = _resolveComponent("el-tab-pane");

      const _component_el_tabs = _resolveComponent("el-tabs");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_option = _resolveComponent("el-option");

      const _component_el_select = _resolveComponent("el-select");

      const _component_el_radio_button = _resolveComponent("el-radio-button");

      const _component_el_radio_group = _resolveComponent("el-radio-group");

      const _component_el_date_picker = _resolveComponent("el-date-picker");

      const _component_el_divider = _resolveComponent("el-divider");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      const _directive_loading = _resolveDirective("loading");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => dialogVisible.value = $event),
        title: "顺丰下单",
        "close-on-click-modal": false
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_3, [_createVNode(_component_el_button, {
          onClick: _cache[12] || (_cache[12] = $event => dialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "success",
          onClick: submitForm
        }, {
          default: _withCtx(() => [_createTextVNode("下单")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_card, {
          class: "box-card mb-20"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", null, _toDisplayString(title.value), 1)])])]),
          _: 1
        }), _withDirectives((_openBlock(), _createBlock(_component_el_form, {
          model: formModel,
          ref_key: "formRef",
          ref: formRef,
          rules: rules
        }, {
          default: _withCtx(() => [_createVNode(_component_el_tabs, {
            modelValue: formModel.sourceArea,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formModel.sourceArea = $event),
            class: "demo-tabs"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
              label: "寄内地",
              name: "CHN"
            }), _createVNode(_component_el_tab_pane, {
              label: "寄港澳台",
              name: "second"
            }), _createVNode(_component_el_tab_pane, {
              label: "寄国际",
              name: "third"
            })]),
            _: 1
          }, 8, ["modelValue"]), _createVNode(_component_el_form_item, {
            label: "商品名称",
            prop: "name",
            "label-width": formLabelWidth
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: formModel.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formModel.name = $event),
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "订单编号",
                prop: "trade_uuid",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: formModel.trade_uuid,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formModel.trade_uuid = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "快递状态",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_select, {
                  modelValue: formModel.expressState,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formModel.expressState = $event),
                  placeholder: "请选择",
                  disabled: ""
                }, {
                  default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(AllDict)['expressStateDict'], (item, index) => {
                    return _openBlock(), _createBlock(_component_el_option, {
                      label: item.label,
                      value: item.value,
                      key: index
                    }, null, 8, ["label", "value"]);
                  }), 128))]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "快递单号",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(formModel.expressNumber || '-暂无信息-'), 1)]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 11
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "快递类型",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_radio_group, {
                  modelValue: formModel.expressTypeId,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => formModel.expressTypeId = $event)
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_radio_button, {
                    label: "1"
                  }, {
                    default: _withCtx(() => [_createTextVNode("顺丰特快")]),
                    _: 1
                  }), _createVNode(_component_el_radio_button, {
                    label: "2"
                  }, {
                    default: _withCtx(() => [_createTextVNode("顺丰标快")]),
                    _: 1
                  })]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 13
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "上门取件时间",
                prop: "sendStartTm",
                "label-width": 145
              }, {
                default: _withCtx(() => [_createVNode(_component_el_date_picker, {
                  modelValue: formModel.sendStartTm,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => formModel.sendStartTm = $event),
                  type: "datetime",
                  placeholder: "请选择日期时间",
                  format: "YYYY-MM-DD HH:mm:ss",
                  "value-format": "YYYY-MM-DD HH:mm:ss"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_divider, {
            "content-position": "left"
          }, {
            default: _withCtx(() => [_createTextVNode("收件人信息")]),
            _: 1
          }), _createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "收件人姓名",
                prop: "acceptInfo.contact",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.acceptInfo.contact,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => formModel.acceptInfo.contact = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "收件人电话",
                prop: "acceptInfo.tel",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.acceptInfo.tel,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => formModel.acceptInfo.tel = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "收件地址",
                prop: "acceptInfo.address",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.acceptInfo.address,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => formModel.acceptInfo.address = $event),
                  autosize: "",
                  type: "textarea",
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_divider, {
            "content-position": "left"
          }, {
            default: _withCtx(() => [_createTextVNode("寄件人信息")]),
            _: 1
          }), _createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "寄件人姓名",
                prop: "sendInfo.contact",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.sendInfo.contact,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => formModel.sendInfo.contact = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "寄件人电话",
                prop: "sendInfo.tel",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.sendInfo.tel,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => formModel.sendInfo.tel = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "寄件人地址",
                prop: "sendInfo.address",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.sendInfo.address,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => formModel.sendInfo.address = $event),
                  autosize: "",
                  type: "textarea",
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])), [[_directive_loading, loading.value]])]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }

};