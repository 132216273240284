import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "wrap"
};
const _hoisted_2 = {
  class: "left"
};
const _hoisted_3 = {
  class: "dialog-footer"
};
import { reactive, ref, computed } from 'vue';
import { AllDict } from '@/utils/common';
import { editOrder } from '@/api/order';
import { ElMessage } from 'element-plus';
const formLabelWidth = '100px';
export default {
  __name: 'modal-info',
  emits: ['refresh'],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const dialogVisible = ref(false);
    const emit = __emit;
    const formRef = ref();
    const formModel = reactive({
      orderId: '',
      totalAmount: '',
      old_discountAmount: '',
      new_discountAmount: '',
      discountAmount: '',
      old_expenses: '',
      new_expenses: '',
      expenses: '',
      address: '',
      recipient: '',
      phone: '',
      orderComment: '',
      expressState: '',
      expressNumber: ''
    });

    const showModal = data => {
      Object.assign(formModel, data);
      Object.assign(formModel, {
        old_discountAmount: data.discountAmount,
        new_discountAmount: data.discountAmount,
        old_expenses: data.expenses,
        new_expenses: data.expenses
      });
      dialogVisible.value = true;
    };

    const hideModal = () => {
      dialogVisible.value = false;
    };

    const title = computed(() => {
      return `订单ID: ${formModel.orderId}`;
    });

    const editOrderFn = async () => {
      try {
        const resData = await editOrder(formModel);

        if (resData && resData.status === 200) {
          ElMessage({
            message: '编辑成功！',
            type: 'success'
          });
          emit('refresh');
          hideModal();
        }
      } catch (err) {
        console.log(err);
      }
    };

    const submitForm = async formEl => {
      await formRef.value.validate((valid, fields) => {
        if (valid) {
          console.log('submit!');
          editOrderFn();
        } else {
          console.log('error submit!', fields);
        }
      });
    };

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_card = _resolveComponent("el-card");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_option = _resolveComponent("el-option");

      const _component_el_select = _resolveComponent("el-select");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => dialogVisible.value = $event),
        title: "编辑订单信息",
        "close-on-click-modal": false
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_3, [_createVNode(_component_el_button, {
          onClick: _cache[12] || (_cache[12] = $event => dialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          onClick: submitForm
        }, {
          default: _withCtx(() => [_createTextVNode("保存")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_card, {
          class: "box-card mb-20"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", null, _toDisplayString(title.value), 1)])])]),
          _: 1
        }), _createVNode(_component_el_form, {
          model: formModel,
          ref_key: "formRef",
          ref: formRef
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "订单编号",
                prop: "trade_uuid",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: formModel.trade_uuid,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formModel.trade_uuid = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "商品名称",
            "label-width": formLabelWidth
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: formModel.subject,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formModel.subject = $event),
              autocomplete: "off",
              disabled: ""
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "商品规格",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.specification,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formModel.specification = $event),
                  autocomplete: "off",
                  disabled: ""
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "订单总金额",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.totalAmount,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formModel.totalAmount = $event),
                  autocomplete: "off",
                  disabled: ""
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "优惠金额",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.new_discountAmount,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => formModel.new_discountAmount = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "运费",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.new_expenses,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => formModel.new_expenses = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "邮寄地址",
            "label-width": formLabelWidth
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: formModel.address,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => formModel.address = $event),
              autosize: "",
              type: "textarea",
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "收件人姓名",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.recipient,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => formModel.recipient = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "收件人电话",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.phone,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => formModel.phone = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "订单备注",
            "label-width": formLabelWidth
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: formModel.orderComment,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => formModel.orderComment = $event),
              autosize: "",
              type: "textarea",
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "快递状态",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_select, {
                  modelValue: formModel.expressState,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => formModel.expressState = $event),
                  placeholder: "请选择"
                }, {
                  default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(AllDict)['expressStateDict'], (item, index) => {
                    return _openBlock(), _createBlock(_component_el_option, {
                      label: item.label,
                      value: item.value,
                      key: index
                    }, null, 8, ["label", "value"]);
                  }), 128))]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "快递单号",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.expressNumber,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => formModel.expressNumber = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }

};