import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-edd0f650"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "wrap"
};
const _hoisted_2 = {
  class: "left"
};
const _hoisted_3 = {
  class: "time-box"
};
const _hoisted_4 = {
  class: "time-text"
};
const _hoisted_5 = {
  class: "dialog-footer"
};
import { reactive, ref, computed } from 'vue';
import { SFIntercept } from '@/api/order';
import { ElMessage } from 'element-plus';
const formLabelWidth = '100px';
export default {
  __name: 'modal-sf-intercept',
  emits: ['refresh'],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const dialogVisible = ref(false);
    const emit = __emit;
    const formRef = ref();
    const loading = ref(false);

    const showModal = data => {
      dialogVisible.value = true;
      Object.assign(formModel, {
        orderId: data.orderId,
        waybillNo: data.expressNumber
      });
    };

    const generateFrom = () => {
      return {
        cancel: false,
        payMode: '1',
        role: '1',
        serviceCode: '1',
        waybillNo: '',
        // 收件人信息
        newDestAddress: {
          address: '河南省郑州市中原区华强广场云翎中心3812室',
          contact: '史先生',
          phone: '18645959323',
          city: '深圳市',
          county: '南山区',
          province: '广东省'
        }
      };
    };

    const formModel = reactive(generateFrom());
    const rules = reactive({
      'newDestAddress.contact': [{
        required: true,
        message: '请输入',
        trigger: 'change'
      }],
      'newDestAddress.address': [{
        required: true,
        message: '请输入',
        trigger: 'change'
      }],
      'newDestAddress.phone': [{
        required: true,
        message: '请输入',
        trigger: 'change'
      }],
      'newDestAddress.province': [{
        required: true,
        message: '请输入',
        trigger: 'change'
      }],
      'newDestAddress.city': [{
        required: true,
        message: '请输入',
        trigger: 'change'
      }],
      'newDestAddress.county': [{
        required: true,
        message: '请输入',
        trigger: 'change'
      }]
    }); // const showModal = (data) => {
    //   Object.assign(formModel, data)
    //   dialogVisible.value = true
    // }

    const hideModal = () => {
      dialogVisible.value = false;
    };

    const title = computed(() => {
      return `订单ID: ${formModel.orderId}`;
    }); // {
    //       cancel: false,
    //       payMode: '1',
    //       role: '1',
    //       serviceCode: formModel.serviceCode,
    //       waybillNo: formModel.expressNumber,
    //       // 收件人信息
    //       newDestAddress: {
    //         address: formModel.newDestAddress.address,
    //         contact: formModel.newDestAddress.contact,
    //         phone: formModel.newDestAddress.phone,
    //         city: formModel.newDestAddress.city,
    //         county: formModel.newDestAddress.county,
    //         province: formModel.newDestAddress.province
    //       }
    //     }

    const SFInterceptFn = async () => {
      try {
        delete formModel.orderId;
        const sendParams = JSON.parse(JSON.stringify(formModel));
        console.log('sendParams : ', sendParams);
        const resData = await SFIntercept(sendParams);

        if (resData && resData.status === 200) {
          ElMessage({
            message: resData.data || '拦截成功！',
            type: 'success'
          });
          emit('refresh');
          hideModal();
        }

        loading.value = false;
      } catch (err) {
        console.log(err);
        loading.value = false;
      }
    };

    const submitForm = async formEl => {
      loading.value = true;
      await formRef.value.validate((valid, fields) => {
        if (valid) {
          console.log('submit!');
          SFInterceptFn();
        } else {
          console.log('error submit!', fields);
        }
      });
    };

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_card = _resolveComponent("el-card");

      const _component_el_radio_button = _resolveComponent("el-radio-button");

      const _component_el_radio_group = _resolveComponent("el-radio-group");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_divider = _resolveComponent("el-divider");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      const _directive_loading = _resolveDirective("loading");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => dialogVisible.value = $event),
        title: "转寄/退回",
        "close-on-click-modal": false
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_5, [_createVNode(_component_el_button, {
          onClick: _cache[7] || (_cache[7] = $event => dialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "danger",
          onClick: submitForm
        }, {
          default: _withCtx(() => [_createTextVNode("拦截")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_card, {
          class: "box-card mb-20"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, _toDisplayString(title.value), 1), _createElementVNode("span", _hoisted_4, "运单号: " + _toDisplayString(formModel.waybillNo), 1)])])]),
          _: 1
        }), _withDirectives((_openBlock(), _createBlock(_component_el_form, {
          model: formModel,
          ref_key: "formRef",
          ref: formRef,
          rules: rules
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 11
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "拦截类型",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_radio_group, {
                  modelValue: formModel.serviceCode,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formModel.serviceCode = $event)
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_radio_button, {
                    label: "1"
                  }, {
                    default: _withCtx(() => [_createTextVNode("转寄")]),
                    _: 1
                  }), _createVNode(_component_el_radio_button, {
                    label: "2"
                  }, {
                    default: _withCtx(() => [_createTextVNode("退回")]),
                    _: 1
                  })]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_divider, {
            "content-position": "left"
          }, {
            default: _withCtx(() => [_createTextVNode("转寄/退回 地址信息")]),
            _: 1
          }), _createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "收件人姓名",
                prop: "newDestAddress.contact",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.newDestAddress.contact,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formModel.newDestAddress.contact = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "收件人电话",
                prop: "newDestAddress.phone",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.newDestAddress.phone,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formModel.newDestAddress.phone = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 8
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "省",
                prop: "newDestAddress.province",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.newDestAddress.province,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formModel.newDestAddress.province = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 8
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "市",
                prop: "newDestAddress.city",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.newDestAddress.city,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => formModel.newDestAddress.city = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 8
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "区",
                prop: "newDestAddress.county",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.newDestAddress.county,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => formModel.newDestAddress.county = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "收件地址",
                prop: "newDestAddress.address",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.newDestAddress.address,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => formModel.newDestAddress.address = $event),
                  autosize: "",
                  type: "textarea",
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])), [[_directive_loading, loading.value]])]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }

};